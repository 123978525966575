import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

// lazy load all the views
const Authorized = React.lazy(() => import('./pages/auth/Authorized'));
const Dashboard = React.lazy(() => import('./pages/dashboards/'));
const Projects = React.lazy(() => import('./pages/dashboards/projects'));
const General = React.lazy(() => import('./pages/dashboards/general'));
const Hosting = React.lazy(() => import('./pages/dashboards/hosting'));
const Editor = React.lazy(() => import('./pages/dashboards/editor'));
const SEO = React.lazy(() => import('./pages/dashboards/seo'));
const SEOWizard = React.lazy(() => import('./pages/dashboards/seo'));
const Form = React.lazy(() => import('./pages/dashboards/form'));
const Integrations = React.lazy(() => import('./pages/dashboards/integrations'));
const Builder = React.lazy(() => import('./pages/site/builder'));
const Checkout = React.lazy(() => import('./pages/site/checkout'));
const Startup = React.lazy(() => import('./pages/site/templates'));
const Preview = React.lazy(() => import('./pages/site/preview'));
const Templates = React.lazy(() => import('./pages/frontend/templates'));
const PreviewTemplate = React.lazy(() => import('./pages/frontend/preview'));
const Portfolio = React.lazy(() => import('./pages/frontend/portfolio'));

//Store
const storeDashboard = React.lazy(() => import('./pages/store/dashboard'));

// account
const Profile = React.lazy(() => import('./pages/account/Profile'));

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const Register = React.lazy(() => import('./pages/account/Register'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/login', name: 'Login', component: Login, route: Route, layout: 'nonauth' },
  { path: '/logout', name: 'Logout', component: Logout, route: Route, layout: 'nonauth' },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route, layout: 'nonauth' },
  { path: '/register', name: 'Register', component: Register, route: Route, layout: 'nonauth' },
  { path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route, layout: 'nonauth' },

  // frontend pages
  { path: '/authorized', name: 'Authorized', component: Authorized, route: Route, layout: 'front' },
  { path: '/templates', name: 'Templates', component: Templates, route: Route, layout: 'front' },
  { path: '/preview/:templateId/:url', name: 'PreviewTemplate', component: PreviewTemplate, route: Route, layout: 'front' },
  { path: '/portfolio', name: 'Portfolio', component: Portfolio, route: Route, layout: 'front' },

  // other pages
  { path: '/projects', name: 'Projects', component: Projects, route: PrivateRoute, roles: ['Admin'], navbar: false, layout: 'auth', apptoggle: false },
  { path: '/site/:siteId/:domain/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/setting/general', name: 'General', component: General, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/hosting', name: 'Hosting', component: Hosting, route: PrivateRoute, roles: ['Admin'], navbar: true, exact: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/hosting/billing/:invoiceNo/complete', name: 'Hosting', component: Hosting, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/setting/editor', name: 'Editor', component: Editor, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/marketing/seo', name: 'SEO', component: SEO, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/marketing/seo/wizard', name: 'SEOWizard', component: SEOWizard, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/form', name: 'Form', component: Form, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/:siteId/:domain/integrations', name: 'Integrations', component: Integrations, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'site' },
  { path: '/site/builder/:templateId/:siteName', name: 'Builder', component: Builder, route: PrivateRoute, roles: ['Admin'], navbar: false, layout: 'auth', apptoggle: false },
  { path: '/site/checkout/:templateId/:packageId', name: 'Checkout', component: Checkout, route: PrivateRoute, roles: ['Admin'], navbar: false, layout: 'auth', apptoggle: false },
  { path: '/site/startup', name: 'Startup', component: Startup, route: PrivateRoute, roles: ['Admin'], navbar: false, layout: 'auth', apptoggle: false },
  { path: '/site/preview/:templateId/:url', name: 'Preview', component: Preview, route: PrivateRoute, roles: ['Admin'], navbar: false, layout: 'auth', apptoggle: false },
  { path: '/account', name: 'account', component: Profile, route: PrivateRoute, roles: ['Admin'], navbar: false, layout: 'auth', apptoggle: false },

  // store pages
  { path: '/store/:siteId/:domain/dashboard', name: 'storeDashboard', component: storeDashboard, route: PrivateRoute, roles: ['Admin'], navbar: true, layout: 'auth', apptoggle: true, appname:'store' },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/projects" />,
    route: PrivateRoute
  },
  
]

export { routes, PrivateRoute };